
import Header from "../Reusables/Header.vue";
import Footer from "../Reusables/Footer.vue";
import ModalPdf from "../Reusables/ModalPdf.vue";
import { XIcon, EyeIcon, DownloadIcon } from '@heroicons/vue/outline'
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    Header,
    Footer,
    ModalPdf,
    EyeIcon,
    DownloadIcon,
    XIcon
  },
  name: 'Normativity',
})
export default class Normativity extends Vue {
  public showModalPdf = false;
  public pdfRoute = "";
    public UDP = [
    {
      title: "Unidades de planeamiento",
      description:
        "UDP 3-01 BUENAVISTA - SANTAFE",
      pdfUrl: "https://drive.google.com/drive/folders/1XNL9vpGEt38cltMaO6UsCs39ykuT1kJJ?usp=sharing",
    },
    {
      title: "Unidades de planeamiento",
      description:
        "UDP 3-02 LA GRANJA - POLICARPA",
      pdfUrl: "https://drive.google.com/drive/folders/1rIAW1KVr9l9qS-Thav0ubjWkl_JsgZaL?usp=sharing",
    },
    {
      title: "Unidades de planeamiento",
      description:
        "UDP 5-01 CENTRO TRADICIONAL",
      pdfUrl: "https://drive.google.com/drive/folders/1CIu31qpVtla-2qofcU0XLVQ_fPbOCtk8?usp=sharing",
    },
    {
      title: "Unidades de planeamiento",
      description:
        "UDP 5-02 CENTRO HASTA LA CIRCUNVALAR",
      pdfUrl: "https://drive.google.com/drive/folders/1xYzlfQxirbGaGPoeWOEYpyUGpDiujZ9M?usp=sharing",
    },
    {
      title: "Unidades de planeamiento",
      description:
        "UDP 5-03 PASATIEMPO - URBINA",
      pdfUrl: "https://drive.google.com/drive/folders/1FUOKCCMY3D4P0P5MMO6uqaau2y2LEL0I?usp=sharing",
    },
    {
      title: "Unidades de planeamiento",
      description:
        "UDP 6-01 SEIS DE MARZO - PRADERA",
      pdfUrl: "https://drive.google.com/drive/folders/1SLmeGwPK4JFhccUPryUkCzadxkvxcyhF?usp=sharing",
    },
    {
      title: "Unidades de planeamiento",
      description:
        "UDP 6-02 CANTACLARO - PP FURATENA",
      pdfUrl: "https://drive.google.com/drive/folders/1qVKKJ1gIY5421kh9UNDsAtrmeQrFwE-8?usp=sharing",
    },
    {
      title: "Unidades de planeamiento",
      description:
        "UDP 7-01 SUCRE",
      pdfUrl: "https://drive.google.com/drive/folders/1XGLtJL1TGzPGP9jnKhouRwoDx_Cx4uDM?usp=sharing",
    },
    {
      title: "Unidades de planeamiento",
      description:
        "UDP 8-01 RECREO - SAN FRANCISCO",
      pdfUrl: "https://drive.google.com/drive/folders/119oR_Ybut2X56th7_ypXYs2gYcImEpYt?usp=sharing",
    },
    {
      title: "Unidades de planeamiento",
      description:
        "UDP 8-02 PICACHO - PP SAN JERONIMO",
      pdfUrl: "https://drive.google.com/drive/folders/1A7GK7uR6LWZacBWRrsV0G_Hi694Ha_1z?usp=sharing",
    },
    {
      title: "Unidades de planeamiento",
      description:
        "UDP 8-03 CASTELLANA- MONTEVERDE",
      pdfUrl: "https://drive.google.com/drive/folders/1jAxaoiYDdMvNQ7RSrdsiGprQnzGBkIUn?usp=sharing",
    },
    {
      title: "Unidades de planeamiento",
      description:
        "UDP 8-04 FLORESTA - SAN ANTONIO - BONANZA",
      pdfUrl: "https://drive.google.com/drive/folders/1OgE_7m63OruXYG4S56Bv6QbfKjiebya3?usp=sharing",
    },
    {
      title: "Unidades de planeamiento",
      description:
        "UDP 9-01 MOCARI",
      pdfUrl: "https://drive.google.com/drive/folders/1uK8kwITR2HYcY7zG9nrRebCs2DGl6zZI?usp=sharing",
    },
    {
      title: "Unidades de planeamiento",
      description:
        "UDP 12-01 VILLA CIELO",
      pdfUrl: "https://drive.google.com/drive/folders/1IEpT2NP6qB_8eHkI2wWBrOs0X9byqDPV?usp=sharing",
    },
  ];
  public bordeOriental = [
    {
      title: "Borde Oriental",
      description:
        "UDP510 -02 - SIERRA CHIQUITA",
      pdfUrl: "../pdf/oriental/BOR1.pdf",
    },
    {
      title: "Borde Oriental",
      description:
        "UDP530 -02 - LA ESTANCIA ",
      pdfUrl: "../pdf/oriental/BOR3.pdf",
    },
  ];
  public bordeOccidental = [
    {
      title: "Borde Occidental",
      description:
        "UDP410 -02 - JUAN XXIII - LAS VILLAS ",
      pdfUrl: "../pdf/occidental/BO1.pdf",
    },
    {
      title: "Borde Occidental",
      description:
        "UDP420 -02 - RANCHO GRANDE - EL DORADO",
      pdfUrl: "../pdf/occidental/BO2.pdf",
    },
  ];
  public ciudadSur = [
    {
      title: "Ciudad sur",
      description:
        "UDP340 -02 - MOGAMBO - GALILEA  ",
      pdfUrl: "../pdf/sur/340-02.pdf",
    },
  ];
  public ciudadCentro = [
    {
      title: "Ciudad centro",
      description:
        "UDP210 -02 - SUCRE ",
      pdfUrl: "../pdf/centro/210-02.pdf",
    },
    {
      title: "Ciudad centro",
      description:
        "UDP220 -02 - ALAMEDAS - LAURELES",
      pdfUrl: "../pdf/centro/210-02.pdf",
    },
    {
      title: "Ciudad centro",
      description:
        "UDP230 -02 - VILLA OLÍMPICA - LOS ÁNGELES  ",
      pdfUrl: "../pdf/centro/230-02.pdf",
    },
    {
      title: "Ciudad centro",
      description:
        "UDP240 -02 - CENTRO TRADICIONAL",
      pdfUrl: "../pdf/centro/240-02.pdf",
    },
    {
      title: "Ciudad centro",
      description:
        "UDP250 -02 - CHUCHURUBÍ - LA CEIBA  ",
      pdfUrl: "../pdf/centro/250-02.pdf",
    },
    {
      title: "Ciudad centro",
      description:
        "UDP260 -02 - LA JULIA - OSPINA PÉREZ ",
      pdfUrl: "../pdf/centro/260-02.pdf",
    },
    {
      title: "Ciudad centro",
      description:
        "UDP270 -02 - LA FLORESTA - LA GALLERA",
      pdfUrl: "../pdf/centro/270-02.pdf",
    },
    {
      title: "Ciudad centro",
      description:
        "UDP280 -02 - CIUDAD CENTRAL  ",
      pdfUrl: "../pdf/centro/240-02.pdf",
    },
  ];
  showModal(url: string) {
    this.showModalPdf = true;
    this.pdfRoute = url;
  }

  closeModal() {
    this.showModalPdf = false;
  }

  downloadItem() {
    const pdf = document.getElementById("pdfForDownload");
    if (pdf != null) pdf.click();
  }
}
